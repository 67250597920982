/* Talking with a contract often involves transforming data, we recommend you to encapsulate that logic into a class */

import { utils } from 'near-api-js';


import { Buffer } from 'buffer';

window.Buffer = Buffer;

const THIRTY_TGAS = '30000000000000';

export class RaffleSite {

  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse
  }

  async getAllRaffles() {
    const raffles = await this.wallet.viewMethod({ contractId: this.contractId, method: "getAllRaffles" })
    return raffles;
  }

  async getRafflesByRaffleID(raffleId) {
    const raffles = await this.wallet.viewMethod({ contractId: this.contractId, method: "getRafflesByRaffleID", args: { raffleId : raffleId } })
    return raffles;
  }

  async getRafflesByAccountId() {
    const raffles = await this.wallet.viewMethod({ contractId: this.contractId, method: "getRafflesByAccountId", args: { sender : this.wallet.accountId } })
    return raffles;
  }

  async getTicketsByRaffleId(raffleId) {
    const tickets = await this.wallet.viewMethod({ contractId: this.contractId, method: "getTicketsByRaffleId", args: { raffleId : raffleId } })
    return tickets;
  }

  async buyTicket(raffleId, noTickets, nDeposit) {
    const gas = '300000000000000';
    const deposit = utils.format.parseNearAmount(nDeposit);
    return await this.wallet.callMethod({ contractId: this.contractId, method: "buy_near_ticket", args: { raffleId: raffleId, noTickets: noTickets }, gas, deposit });
  }

  async buyFtTicket(raffle_id, noTickets, nDeposit, ft_contract) {
    const gas = '300000000000000';
    const dep = utils.format.parseNearAmount(nDeposit);
    const deposit = utils.format.formatNearAmount('1000000000000000000000000');
    return await this.wallet.callMethod({ contractId: ft_contract, method: "ft_transfer_call", args: { receiver_id: this.contractId, amount: dep, msg: `{"raffle_id":"${raffle_id}", "noTickets":"${noTickets}"}` }, gas, deposit });
  }

  async transferNft(nft_contract_id, token_id, raffle_id) {
    const gas = '300000000000000';
    const deposit = utils.format.formatNearAmount('1000000000000000000000000');
    return await this.wallet.callMethod({ contractId: nft_contract_id, method: "nft_transfer_call", args: { receiver_id: this.contractId, token_id: token_id, msg: `{"raffle_id":"${raffle_id}"}` }, gas, deposit });
  }

  async createRaffle(contract_id, nft_id, ft_token, price, featured, raffleDuration, nDeposit) {
    const deposit = utils.format.parseNearAmount(nDeposit);
    const nprice = utils.format.parseNearAmount(price);
    return await this.wallet.callMethod({ contractId: this.contractId, method: "createRaffle", args: { contract_id: contract_id, nft_id: nft_id, ft_token: ft_token, price: nprice, featured: featured, raffleDuration: raffleDuration }, THIRTY_TGAS, deposit });
  }

  async completeRaffle(raffle_id) {
    const gas = '300000000000000';
    return await this.wallet.callMethod({ contractId: this.contractId, method: "completeRaffle", args: { raffleId: raffle_id }, gas });
  }

  async claim_prize(raffle_id) {
    const deposit = utils.format.parseNearAmount('0.0002');
    const gas = '300000000000000';
    return await this.wallet.callMethod({ contractId: this.contractId, method: "claim_prize", args: { raffleId: raffle_id }, gas, deposit });
  }

  async getExpiredRaffle() {
    const raffles = await this.wallet.viewMethod({ contractId: this.contractId, method: "getExpiredRaffle" })
    return raffles;
  }

  async nft_tokens_for_owner(contractId) {
    return await this.wallet.viewMethod({ contractId: contractId, method: "nft_tokens_for_owner", args: { account_id: this.wallet.accountId } });
  }

  async nft_metadata(contractId) {
    return await this.wallet.viewMethod({ contractId: contractId, method: "nft_metadata" });
  }

  async get_all_nft() {
    const nft_contracts = await this.wallet.viewMethod({ contractId: this.contractId, method: "get_active_nft" })
    return nft_contracts;
  }

  async get_nft_by_contract(nft_contract_address) {
    const nft_contracts = await this.wallet.viewMethod({ contractId: this.contractId, method: "get_nft_by_contract",  args: { nft_contract_address: nft_contract_address }  })
    return nft_contracts;
  }

  async get_all_ft_token() {
    const ft_tokens = await this.wallet.viewMethod({ contractId: this.contractId, method: "get_active_ft_token" })
    return ft_tokens;
  }

  async get_ft_token_by_id(ft_token) {
    const ft_tokens = await this.wallet.viewMethod({ contractId: this.contractId, method: "get_ft_token_by_id", args: { ft_contract_address: ft_token } })
    return ft_tokens;
  }

}