import React from 'react';
import { useState, useEffect } from 'react';
import { Link as RLink } from 'react-router-dom';
import RaffleMedia from './RaffleMedia';
import { MyRaffle } from './MyRaffle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export function Raffles({ isSignedIn, raffleSite, wallet }) {

  const [raffles, getRaffles] = useState([]);

  useEffect(() => {
    raffleSite.getAllRaffles().then(getRaffles);
  }, [raffleSite]);


  const activeRaffles = raffles.filter(item => item.isActive === true && item.isCompleted === false);
  const sortRaffles = activeRaffles.sort((a, b) => a.endDate - b.endDate);
  const featuredRaffles = sortRaffles.filter(item => item.featured === true);
  const nonFeaturedRaffles = sortRaffles.filter(item => item.featured === false);
  const completedRaffles = raffles.filter(item => item.isCompleted === true);


  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  return (
    <>
    <Grid container direction="column" justifyContent={"center"} align="center">
    <Grid item>
      <Typography variant="h4" sx={{ fontWeight: 800, letterSpacing: '.2rem' }} >
       VEX RAFFLE
      </Typography>
    </Grid>
    <Grid item>
      <Typography sx={{ fontSize: 14, fontWeight: 200, letterSpacing: '.1rem' }}>
        Vex Raffle is a $NEAR raffle site built by <strong>Vex Labs.</strong> <br/>
        Anyone can create a raffle for their NFT for a small fee of 0.2 Ⓝ and 5% fee on tickets.<br/>
        To get your token or NFT added, connect with us on <a href="https://dsc.gg/vexedapesclub">discord</a>.<br/>
      </Typography>
    </Grid>
  </Grid>
  <br/><br/>
    {wallet.accountId && (
        <Grid container justifyContent={"right"}>
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            sx={{maxWidth:'300'}}
            spacing={2}
          >
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              maxWidth: 300,
              m: 1,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} container justifyContent="center">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    textDecoration: 'none',
                  }}
                >
                  CREATE NEW RAFFLE
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} container justifyContent="center">
                <Button variant="contained" component={RLink} to="/Create">
                  CREATE
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      )
    }
    <Box>            
      <Grid container justifyContent={"center"}>
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Active" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
            <Tab label="Completed" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
            <Tab label="My Raffles" sx={{
                        fontWeight: 800,
                        letterSpacing: '.1rem',
                        textDecoration: 'none',
                        fontSize: 16
                      }} />
          </Tabs>
        </Box>
      </Grid>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 0 && (
          <Box>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{width:'80%'}}
                spacing={2}
              >
                {activeRaffles.length === 0 && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        letterSpacing: '.2rem',
                        textDecoration: 'none',
                        mt: 3
                      }}
                    >
                      NO ACTIVE RAFFLES
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Button variant="contained" component={RLink} to="/Create">
                      CREATE
                    </Button>
                  </Grid>
                </Grid>)}
                {featuredRaffles.map((raffle, i) =>
                  <Grid item  key={i}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea  component={RLink} to={`/${raffle.raffle_id}`} >
                      <RaffleMedia raffleSite = {raffleSite} raffle = {raffle} wallet = {wallet} />
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
                {nonFeaturedRaffles.map((raffle, i) =>
                  <Grid item  key={i}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea  component={RLink} to={`/${raffle.raffle_id}`} >
                      <RaffleMedia raffleSite = {raffleSite} raffle = {raffle} wallet = {wallet} />
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 1 && (
          <Box>
            <Grid container justifyContent={"center"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{width:'80%'}}
                spacing={2}
              >
                {completedRaffles.map((raffle, i) =>
                  <Grid item  key={i}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea  component={RLink} to={`/${raffle.raffle_id}`} >
                      <RaffleMedia raffleSite = {raffleSite} raffle = {raffle} wallet = {wallet} />
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Box>
        {tabIndex === 2 && (
          <MyRaffle isSignedIn={isSignedIn} raffleSite={raffleSite} wallet={wallet} />
        )}
      </Box>
    </Box>
    </>
  );
}
