import React from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { Outlet } from "react-router-dom";
//import { useState, useEffect } from 'react';

// /* global BigInt */

const App = ({ isSignedIn, raffleSite, wallet }) => {
/* 
  const [raffles, Raffles] = useState([]);

  useEffect(() => {
    raffleSite.getAllRaffles().then(Raffles);
  }, [raffleSite]);

  useEffect(() => {
    for (let i = 0; i < raffles.length; i++) {
      if(raffles[i].isActive === true && raffles[i].isCompleted === false && raffles[i].endDate < BigInt(Date.now() * 1000000)) {
        raffleSite.completeRaffle(raffles[i].raffle_id);
      }
    }
  }, [raffles, raffleSite]); */

  return (
    <main>

      <Navigation isSignedIn = {isSignedIn} wallet = {wallet} />

      <Outlet />

      <Footer />
    
    </main>
  );
};

export default App;