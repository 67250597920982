import React from 'react';
import { Link } from "react-router-dom";
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export function Terms() {

  const steps = [
    'Accept Terms & Conditions',
    'Select NFT to Raffle',
    'Create Raffle',
    'Activate the Raffle'
  ];

  const [checked, setChecked] = useState([]);

  const handleChange1 = (event) => {
    setChecked([event.target.checked]);
  };

  return (
    <>
      <Box>
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography gutterBottom variant="h3" component="div" align={"center"}>
          <br/>Terms & Conditions
        </Typography><br/>
      </Box>
      <Grid container justifyContent={"center"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width:'90%'}}
          >
            <Grid item xs={12} sm={12}>
              <Box sx={{ m: 2 }}>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    1.
                  </Box>
                  Creating a raffle costs a small non-refundable fee of 0.2 Ⓝ.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    Featured raffles cost an extra 0.1 Ⓝ.
                  </Box>
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    2.
                  </Box>
                  Activating the raffle will transfer the NFT prize to the Raffle Wallet.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    The raffle will not appear until it has been activated.
                  </Box>
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    3.
                  </Box>
                  You can specify the ticket currency, price and raffle duration.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    Currently, only NEAR is available, more will be available soon.
                  </Box>
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    4.
                  </Box>
                  A small 5% fee will be charged per ticket purchased.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    The funds will go directly to the raffle owner wallet.
                  </Box>
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    5.
                  </Box>
                  The raffle will be completed automatically regardless of how many tickets are sold.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    If no tickets were sold, the NFT prize will be returned.
                  </Box>
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  <Box sx={{ color: 'text.primary', fontSize: 12, display: 'inline', mr: 1 }}>
                    6.
                  </Box>
                  Raffles cannot be edited / deleted.
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline', ml: 1 }}>
                    Please make sure to enter all details correctly.
                  </Box>
                </Box>

              </Box>
            </Grid>
          <Grid item xs={12} sm={12} align="right">
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={handleChange1} />}  label="Tick here to accept our Terms & Conditions" labelPlacement="start" />
          </FormGroup>
            <Button variant="contained" disabled={!checked[0]} component={Link} to={'/Create/Owned'} >Accept</Button>
          </Grid>
          </Grid>
      </Grid>
    </>
  );
}
