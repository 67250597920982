import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import RaffleMedia from './RaffleMedia';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import SignIn from './SignIn';

export function MyRaffle({ isSignedIn, raffleSite, wallet }) {

  const [raffles, getMyRaffles] = useState([]);

  useEffect(() => {
    raffleSite.getRafflesByAccountId().then(getMyRaffles);
  }, [raffleSite]);
 
  return (
    <>
        { isSignedIn
          ? (<Box><Grid container justifyContent={"center"}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{width:'80%'}}
              spacing={2}
            >
              {raffles.map((raffle, i) =>
                <Grid item  key={i}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea  component={Link} to={`/${raffle.raffle_id}`} >
                      <RaffleMedia raffleSite = {raffleSite} raffle = {raffle} wallet = {wallet} />
                    </CardActionArea>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} sx={{ mt:5 }}><Button variant="contained" component={Link} to="/Create">Create Raffle</Button></Grid>
          </Box>)
          : <SignIn/>
        }
    </>
  );
}