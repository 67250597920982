import React from 'react';
import { Outlet } from "react-router-dom";
import SignIn from './SignIn';

export function Create({ isSignedIn }) {

  return (
    <>
      { isSignedIn
        ? <Outlet />
        : <SignIn/>
      }
    </>
  );
}
