import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import battle from "./battle.png";
import market from "./market.png";
import stake from "./stake.png";
import raffle from "./raffle.png";

export default function Navigation({ isSignedIn, wallet }) {
  return (
    <Box sx={{ padding: 3, mb: 6 }}>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Card sx={{ width: 250 }}>
              <CardActionArea href="https://market.vexedapes.com">
                <CardMedia
                  component="img"
                  height="200"
                  image={market}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    MARKET
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vex Market allows the exchange of Fungible Tokens on $NEAR
                    protocol.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ width: 250 }}>
              <CardActionArea href="https://battle.vexedapes.com">
                <CardMedia
                  component="img"
                  height="200"
                  image={battle}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Battle
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vex Battle is a fun PvP minigame where two players can
                    battle for $NEAR.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ width: 250 }}>
              <CardActionArea component={Link} to="/">
                <CardMedia
                  component="img"
                  height="200"
                  image={raffle}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Raffle
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vex Raffle allows you to raffle any $NEAR NFT or win NFTs
                    for a fraction of the cost.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ width: 250 }}>
              <CardActionArea href="https://staking.vexedapes.com">
                <CardMedia
                  component="img"
                  height="200"
                  image={stake}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Staking
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vex Stake is a service to earn tokens for Vexed Apes and
                    other $NEAR projects.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
