import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { utils } from 'near-api-js';
import Alert from '@mui/material/Alert';

const Input = styled(MuiInput)`
  background: #011121;
`;

export default function BuyTicket({ raffleSite, raffleId, rafflePrice, accountId, wallet, ft }) {

  const [tickets, getTickets] = useState([]);

  useEffect(() => {
    raffleSite.getTicketsByRaffleId(raffleId).then(getTickets);
  }, [raffleSite, raffleId]);

  const ticks = tickets.filter(item => item === accountId);
  const myticks = ticks.length;

  const buyTicket = async (e) => {
    searchParams.delete('transactionHashes');
    searchParams.delete('errorMessage');
    searchParams.delete('errorCode');
    setSearchParams(searchParams);
    if (ft.ft_contract_address === 'near') {
      let nDeposit = (value * utils.format.formatNearAmount(rafflePrice)) + 0.00098;
      await raffleSite.buyTicket(raffleId, value, `${nDeposit}`);
    } else {
      let deposit = (value * utils.format.formatNearAmount(rafflePrice))
      await raffleSite.buyFtTicket(raffleId, value, `${deposit}`, ft.ft_contract_address);
    }
  };

  // slider code
  const [value, setValue] = React.useState(1);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 20) {
      setValue(20);
    }
  };


  const [searchParams, setSearchParams] = useSearchParams();
  const errCode = searchParams.get('errorCode');
  const txHash = searchParams.get('transactionHashes');

  const [txR, getTxR] = useState([]);

  useEffect(() => {
    if(txHash !== null){
      wallet.getTransactionResult(txHash).then(getTxR)
    }
  }, [wallet, txHash]);

  function setAlert() {
    if(txHash !== null){
      if(txR === true) {
        return (
          <Alert severity="success" color="success" variant="filled"
            sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
          >
            Raffle activated successfully
          </Alert>
        )
      }
    } else if(errCode !== null) {
      return (
        <Alert severity="error" color="warning" variant="filled"
          sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
        >
          Action failed
        </Alert>
      )
    }
  }

  return (
    <>
      <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
        MY TICKETS
      </Box>
      <Box sx={{ m: 1 }}>
        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 18 }}>
          {myticks}
        </Box>
          <Box
          sx={{
            color: 'text.secondary',
            display: 'inline',
            fontWeight: 'light',
            fontSize: 14, 
          }}
        >
          &nbsp;/ {tickets.length} 
          <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
            &nbsp;overall
          </Box>
        </Box>
      </Box>
      <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
        BUY
      </Box>
      <Box sx={{ m: 1, ml: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
            <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={1}
            max={20}
            color="secondary"
            />
        </Grid>
        <Grid item>
            <Input
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
                step: 1,
                min: 1,
                max: 20,
                type: 'number',
                'aria-labelledby': 'input-slider',
            }}
            />
        </Grid>
      </Grid>
      </Box>
      <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
        TOTAL COST
      </Box>
      <Box sx={{ color: 'text.primary', fontSize: 16, fontWeight: 'regular', m: 1 }}>
        {(value * (100 * utils.format.formatNearAmount(rafflePrice))) / 100} {ft?.ft_symbol}
      </Box>
      <Button variant="contained"  onClick={buyTicket}>Buy Ticket</Button>
      {setAlert()}
    </>
  );
}
