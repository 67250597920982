import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export function Owned({ raffleSite }) {

  const [nft_contracts, getNft] = useState([]);

  useEffect(() => {
    raffleSite.get_all_nft().then(getNft);
  }, [raffleSite]);

  const [owned_nft, setData] = useState([]);

  useEffect(() => {
    for (let i = 0; i < nft_contracts.length; i++) {
      const fetchData = async () => {
        // get the data from the api
        const data = await raffleSite.nft_tokens_for_owner(nft_contracts[i].nft_contract_address);
        const metadata = await raffleSite.nft_metadata(nft_contracts[i].nft_contract_address);
        for (let z = 0; z < data.length; z++){
          data[z]["contract_id"] = nft_contracts[i].nft_contract_address;
          data[z]["nft_name"] = metadata.name;
          data[z]["base_uri"] = metadata.base_uri;
          setData(current => [...current, data[z]]);
        }
      }
        // call the function
        fetchData()
          // make sure to catch any error
          .catch(console.error);
    }
  }, [nft_contracts, raffleSite]);

  const steps = [
    'Accept Terms & Conditions',
    'Select NFT to Raffle',
    'Create Raffle',
    'Activate the Raffle'
  ];


  return (
    <>
      <Box>
        <Stepper activeStep={1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography gutterBottom variant="h3" component="div" align={"center"}>
          Select NFT
        </Typography><br/>
      </Box>
      <Grid container justifyContent={"center"}>
      <Outlet />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="top"
        sx={{width:'80%'}}
        spacing={2}
      >
      {owned_nft.map((owned_nft, i) =>
        <Grid item key={i}>
          <Card sx={{ minWidth: 250, maxWidth: 250 }}>
            <CardActionArea  component={Link} to={`/Create/${owned_nft.contract_id}/${owned_nft.token_id}`} >
              <CardMedia
                component="img"
                height="250"
                image={`${owned_nft.base_uri}/${owned_nft.token_id}.png`}
                alt={owned_nft.metadata.description}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {owned_nft.nft_name}
                  <Typography sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline' ,   ml: 1 }}>
                    # {owned_nft.token_id}
                  </Typography>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
      </Grid>
      </Grid>

        <Typography gutterBottom variant="h6" component="div" align={"center"}>
          <br/>If your NFT is not listed, please reach out on <a target="_blank" rel="noreferrer" href="https://dsc.gg/vexedapesclub">Discord</a>.
        </Typography>
    </>
  );
}
