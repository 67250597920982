// React
import React from 'react';
import App from './App';
import Apps from './components/apps/Apps';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Raffle } from "./components/Raffle";
import { Raffles } from "./components/Raffles";
import { MyRaffle } from "./components/MyRaffle";
import { Create } from "./components/Create";
import { Owned } from "./components/Owned";
import { Terms } from "./components/Terms";
import { CreateNFT } from "./components/CreateNFT";


//MUI
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// NEAR
import { RaffleSite } from './near-interface';
import { Wallet } from './near-wallet';

import { Buffer } from 'buffer';


window.Buffer = Buffer;

// When creating the wallet you can choose to create an access key, so the user
// can skip signing non-payable methods when talking wth the  contract
const wallet = new Wallet({ createAccessKeyFor: process.env.REACT_APP_CONTRACT_ADDRESS })

// Abstract the logic of interacting with the contract to simplify your flow
const raffleSite = new RaffleSite({ contractId: process.env.REACT_APP_CONTRACT_ADDRESS, walletToUse: wallet });

// Setup on page load
window.onload = async () => {
  const isSignedIn = await wallet.startUp()

  const container = document.getElementById('root')
  const root = createRoot(container); 
  root.render(<ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Routes>
      <Route path="/" element={<App isSignedIn={isSignedIn} raffleSite={raffleSite} wallet={wallet} />}>
        <Route path="MyRaffle" element={<MyRaffle isSignedIn={isSignedIn} raffleSite={raffleSite} wallet={wallet} />} />
        <Route path="" element={<Raffles isSignedIn={isSignedIn} raffleSite={raffleSite} wallet={wallet} />} />
        <Route path=":raffleNo" element={<Raffle raffleSite={raffleSite} wallet={wallet} />} />
        <Route path="Create" element={<Create isSignedIn={isSignedIn} />}>
          <Route path="" element={<Terms raffleSite={raffleSite} wallet={wallet} />} />
          <Route path="Owned" element={<Owned raffleSite={raffleSite} />} />
          <Route path=":contractId/:tokenId" element={<CreateNFT raffleSite={raffleSite} wallet={wallet}  />} />
        </Route>
        <Route path="app" element={<Apps />} />
      </Route>
      </Routes>
    </Router>
    </ThemeProvider>);
}