import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

export default function Tickets({ raffleSite, raffleId, accountId }) {

  const [tickets, getTickets] = useState([]);

  useEffect(() => {
    raffleSite.getTicketsByRaffleId(raffleId).then(getTickets);
  }, [raffleId, raffleSite]);

  const ticks = tickets.filter(item => item === accountId);
  const myticks = ticks.length;

  return (
    <>
      <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
        MY TICKETS
      </Box>
      <Box sx={{ m: 1 }}>
        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 18 }}>
          {myticks}
        </Box>
          <Box
          sx={{
            color: 'text.secondary',
            display: 'inline',
            fontWeight: 'light',
            fontSize: 14, 
          }}
        >
          &nbsp;/ {tickets.length} 
          <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
            &nbsp;overall
          </Box>
        </Box>
      </Box>
    </>
  );
}
