import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BuyTicket from './BuyTicket';
import Winner from './Winner';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { utils } from 'near-api-js';
import Countdown from 'react-countdown';

export const Raffle = ({ raffleSite, wallet }) => {
  
  let { raffleNo } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [raffleNo]);

  let raffleId = Number(raffleNo);

  const [raffle, getRaffles] = useState([]);

  useEffect(() => {
    raffleSite.getRafflesByRaffleID(raffleId).then(getRaffles);
  }, [raffleSite, raffleId]);

  const [nftcontract, getNft] = useState([]);

  useEffect(() => {
    if(raffle.nft_contract_id === undefined) {
    } else {
      raffleSite.nft_metadata(raffle.nft_contract_id).then(getNft);
    }
  }, [raffleSite, raffle]);

  const [ft, getFt] = useState([]);

  useEffect(() => {
    if(raffle.ft_token === undefined) {
    } else {
      raffleSite.get_ft_token_by_id(raffle.ft_token).then(getFt);
    }
  }, [raffleSite, raffle]);

  const Completionist = () => <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128308;</span> Raffle Ended</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128994;</span> {days} <span style={{fontSize: 12}}>days</span>&nbsp; {hours} <span style={{fontSize: 12}}>hours</span>&nbsp; {minutes} <span style={{fontSize: 12}}>mins</span></span>
    }
  };

  function raffleStatus() {
    if(raffle.isActive) {
      if(raffle.isCompleted) {
        return <Winner raffleSite = {raffleSite} raffle={raffle} wallet={wallet} />
      } else if (raffle.endDate/1000000 < Date.now()) {
        return <Button variant="contained" color="secondary" size="large" onClick={() => raffleSite.completeRaffle(raffle.raffle_id)}>REVEAL WINNER</Button>
      } else {
        return <BuyTicket raffleSite = {raffleSite} raffleId = {raffleId} rafflePrice = {raffle.price} accountId = {wallet.accountId} wallet = {wallet} ft={ft[0]} /> 
      }
    } else {
      if (wallet.accountId === raffle.owner_id) {
        return <Button variant="contained" color="secondary" size="large" onClick={() => raffleSite.transferNft(raffle.nft_contract_id, raffle.token_id, raffle.raffle_id)}> ACTIVATE </Button>
      }
    }
  }

  return (
    <>
      <Button sx={{ pl: 4, pb:1 }} color="secondary" component={Link} to="/">&lt; Home</Button>
      <Typography gutterBottom variant="h3" component="div" align={"center"}>
        Raffle {raffleNo}
      </Typography><br/>
      <Grid container justifyContent={"center"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width:'80%'}}
          >
            <Grid item xs={12} sm={6}>
            <img
              width="100%"
              src={`${nftcontract.base_uri}/${raffle.token_id}.png`}
              alt={nftcontract.name}
            />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <Box sx={{ m: 2 }}>
              <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                NFT
              </Box>
              <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                {nftcontract.name}
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline' ,   ml: 1 }}>
                  # {raffle.token_id}
                </Box>
              </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
                  CREATED BY
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1 }}>
                  {raffle.owner_id}
                </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
                  RAFFLE PRICE
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1 }}>
                {`${utils.format.formatNearAmount(raffle.price)}`} {ft[0]?.ft_symbol}
                </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                  ENDS IN
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1 }}>
                {raffle.isActive
                  ? <Countdown date={Math.floor(raffle.endDate/1000000)} renderer={renderer} />
                  : <></>
                }
                </Box>
                { raffleStatus() }
              </Box>
            </Grid>
          </Grid>
        </Grid>
    </>
  );
}
