import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';

export const CreateNFT = ({ raffleSite, wallet }) => {

  let { contractId, tokenId } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [contractId, tokenId]);

  const [nftcontract, getNft] = useState([]);

  useEffect(() => {
    raffleSite.nft_metadata(contractId).then(getNft);
  }, [raffleSite, contractId]);

  const defaultValues = {
    fttoken: '',
    price: '',
    featured: false,
    duration: ''
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSliderChange = (event) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formValues);
    if (formValues.featured === true){
      searchParams.delete('transactionHashes');
      searchParams.delete('errorMessage');
      searchParams.delete('errorCode');
      setSearchParams(searchParams);
      let ndeposit = "0.3"
      await raffleSite.createRaffle(contractId, tokenId, formValues.fttoken, formValues.price, formValues.featured, formValues.duration, ndeposit)
    } else {
      searchParams.delete('transactionHashes');
      searchParams.delete('errorMessage');
      searchParams.delete('errorCode');
      setSearchParams(searchParams);
      let ndeposit = "0.2"
      await raffleSite.createRaffle(contractId, tokenId, formValues.fttoken, formValues.price, formValues.featured, formValues.duration, ndeposit)
    }
  };

  const TextFields = styled(TextField)`
  background: #011121;
  `;

  const Selects = styled(Select)`
  background: #011121;
  `;

  const steps = [
    'Accept Terms & Conditions',
    'Select NFT to Raffle',
    'Create Raffle',
    'Activate the Raffle'
  ];


  const [searchParams, setSearchParams] = useSearchParams();
  const errCode = searchParams.get('errorCode');
  const txHash = searchParams.get('transactionHashes');

  const [txR, getTxR] = useState([]);

  useEffect(() => {
    if(txHash !== null){
      wallet.getTransactionResult(txHash).then(getTxR)
    }
  }, [wallet, txHash]);

  function setAlert() {
    if(txHash !== null){
      return (
        <Alert severity="success" color="success" variant="filled"
          sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
          action={
            <Button variant="contained" color="secondary" size="small" component={Link} to={`/${txR.raffle_id}`}>
              ACTIVATE
            </Button>
          }
        >
          Raffle created successfully
        </Alert>
      )
    } else if(errCode !== null) {
      return (
        <Alert severity="error" color="warning" variant="filled"
          sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 200 }}
        >
          Raffle was not created
        </Alert>
      )
    }
  }


  return (
    <>
      <Box>
        <Stepper activeStep={2} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography gutterBottom variant="h3" component="div" align={"center"}>
          <br/>Create Raffle
        </Typography><br/>
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{width:'80%'}}
        spacing={2}
        >
        <Grid item xs={12} sm={6}>
          <img width="100%" alt="nft" src={`${nftcontract.base_uri}/${tokenId}.png`} />
        </Grid>
        <Grid item  xs={12} sm={6}>
        <Box sx={{ minWidth: 120, maxWidth: 300 }}>
        <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  ml: 2  }}>
          NFT
        </Box>
        <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular',   ml: 2  }}>
          {nftcontract.name}
          <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline' ,   ml: 1 }}>
            # {tokenId}
          </Box>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ m: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="ft-token-label">Currency *</InputLabel>
            <Selects
              required
              labelId="ft-token-label"
              label="Currency"
              id="fttoken"
              name="fttoken"
              value={formValues.fttoken}
              onChange={handleInputChange}
            >
              <MenuItem value={"near"}>NEAR</MenuItem>
              <MenuItem value={"vexedapes_token.near"}>VAC</MenuItem>
            </Selects>
          </FormControl>
          </Box>
          <Box sx={{ m: 2 }}>
          <TextFields
            required
            fullWidth
            id="price"
            name="price"
            label="Price"
            type="text"
            value={formValues.price}
            onChange={handleInputChange}
          />
          </Box>
          <Box sx={{ m: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="duration-label">Raffle Duration *</InputLabel>
            <Selects
              required
              labelId="duration-label"
              label="Raffle Duration"
              id="duration"
              name="duration"
              value={formValues.duration}
              onChange={handleInputChange}
            >
            <MenuItem value={"86400000000000"}>1 day</MenuItem>
            <MenuItem value={"259200000000000"}>3 days</MenuItem>
            <MenuItem value={"432000000000000"}>5 days</MenuItem>
            </Selects>
          </FormControl>
          </Box>
          <Box sx={{ m: 2, ml: 3 }}>
          <FormControlLabel
            control={
              <Switch checked={formValues.featured} onChange={handleSliderChange} name="featured" color="secondary" />
            }
            label="Featured"
            labelPlacement="end"
          />
          <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline'  }}>
            + 0.1 Ⓝ
          </Box>
          </Box>
          <Box sx={{ m: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          </Box>
        </form>
        </Box>
        </Grid>
      </Grid>
      </Grid>
      { setAlert() }
    </>
  );
}
