import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function Winner({ raffleSite, raffle, wallet }) {

  const claimClick = async (e) => {
    await raffleSite.claim_prize(raffle.raffle_id);
  };

  function displayWallet(wallet) {
    if(wallet.length > 16) {
      return `${wallet.substring(0,13)}...`
    } else {
      return `${wallet}`
    }
  }

  return (
    <>
        <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
            Winner
        </Box>
        <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
            {displayWallet(raffle.winner_AccountID)}
            <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline' ,   ml: 1 }}>
                # {raffle.winner_TicketID} 
            </Box>
        </Box>
        { raffle.winner_AccountID === wallet.accountId && raffle.prize_claimed === false
          ? <Button variant="contained" color="secondary" size="large" onClick={claimClick}> CLAIM </Button>
          : <></>
        }
    </>
  );
}