import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function SignIn() {
  return (
    <>
      <Grid justifyContent={"center"} sx={{mt:2}}>
      <Link underline="none" color="inherit" target="_blank" rel="noreferrer" href={"https://paras.id/collection/vexedapesclub.near"}>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          width="90%" 
          direction="row"
          spacing={2}
          sx={{ backgroundColor:"black.main",borderRadius:'20px' }}
        >
          <Grid item sm={2} xs={2} >
            <img width="80px" alt="logo" src="https://vexedapesclub.com/wp-content/uploads/2022/06/newlogo.png" />
          </Grid>
          <Grid item sm={8} xs={9}>
            <Box sx={{ fontSize:20, fontWeight: '500', letterSpacing: '.1rem', display: 'flex', mr:2,  alignItems: "center", justifyContent:"center" }}>
              VEXED APES CLUB
            </Box>
          </Grid>
          <Grid item sm={2} xs={1} >
            <Box sx={{ display: 'flex', mr:2,  alignItems: "center", justifyContent:"right" }}>
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px"><path d="M2.063 16 0 0l8.718 1.6c.37.1.714.182 1.03.257 1.188.28 1.992.47 2.427 1.279.55 1.017.825 2.268.825 3.755 0 1.493-.275 2.748-.825 3.765-.55 1.017-1.703 1.525-3.457 1.525H4.942L5.664 16h-3.6zM3.198 3.307l4.706.854c.2.054.385.097.555.137.641.15 1.075.252 1.31.684.297.543.445 1.211.445 2.005 0 .798-.148 1.468-.445 2.012-.297.543-.919.814-1.865.814h-2.15L4.46 9.707l-1.26-6.4z" fillRule="evenodd"></path></svg>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      </Link>
      </Grid>
      <Typography gutterBottom variant="h6" component="div" align={"center"} sx={{ fontWeight: 'light', letterSpacing: '.3rem' }}>
        <br/>CONNECT YOUR NEAR WALLET
      </Typography>
    </>
  );
}