import React from 'react';
import { utils } from 'near-api-js';
import { useState, useEffect } from 'react';
import Tickets from './Tickets';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Countdown from 'react-countdown';
import Chip from '@mui/material/Chip';

export default function RaffleMedia({ raffleSite, raffle, wallet }) {

  const [nft, getNft] = useState([]);

  useEffect(() => {
    raffleSite.nft_metadata(raffle.nft_contract_id).then(getNft);
  }, [raffleSite, raffle]);

  const [ft, getFt] = useState([]);

  useEffect(() => {
    if(raffle.ft_token === undefined) {
    } else {
      raffleSite.get_ft_token_by_id(raffle.ft_token).then(getFt);
    }
  }, [raffleSite, raffle]);

  const Completionist = () => <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128308;</span> Raffle Ended</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (raffle.isActive) {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128994;</span> {days} <span style={{fontSize: 12}}>days</span>&nbsp; {hours} <span style={{fontSize: 12}}>hours</span>&nbsp; {minutes} <span style={{fontSize: 12}}>mins</span></span>
      }
    } else {
      return <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128993;</span> Raffle Inactive</span>
    }
  };

  return (
    <>
              <CardMedia
                component="img"
                height="300"
                image={`${nft.base_uri}/${raffle.token_id}.png`}
                alt={raffle.nft_contract_id}
              />
              <CardContent>
                <Box sx={{ color: 'text.primary', fontSize: 14, fontWeight: 'regular', mb: 2 }}>
                  RAFFLE # {raffle.raffle_id} &nbsp;&nbsp;
                  {raffle.featured
                  ? <Chip label="Featured" variant="filled" size="small" color="secondary" />
                  : <></>
                  }
                </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light'  }}>
                  NFT
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1  }}>
                  {nft.name}
                  <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light',  display: 'inline' ,   ml: 1 }}>
                    # {raffle.token_id}
                  </Box>
                </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, fontWeight: 'light' }}>
                  RAFFLE PRICE
                </Box>
                <Box sx={{ color: 'text.primary', fontSize: 18, fontWeight: 'regular', m: 1 }}>
                {`${utils.format.formatNearAmount(raffle.price)}`} {ft[0]?.ft_symbol}
                </Box>
                <Tickets raffleSite = {raffleSite} raffleId = {raffle.raffle_id} accountId = {wallet.accountId} />
                <hr style={{marginTop:10}}/>
                <Box align="center" sx={{ color: 'text.secondary', fontSize: 16, fontWeight: 'regular', mt: 2 }}>
                  {raffle.isActive
                    ? <Countdown date={Math.floor(raffle.endDate/1000000)} renderer={renderer} />
                    : <span><span style={{fontSize: 8, verticalAlign: "middle"}}>&#128993;</span> Raffle Inactive</span>
                  }
                </Box>
              </CardContent>
        
    </>
  );
}
